/* ImageSlider.css */

.slider-container {
    width: 100%;
    /* height: 600px; */
  }
  
  .slick-prev:before,
  .slick-next:before {
    color: black; /* Arrow color */
  }
  
  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
    background: white; /* Arrow background */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  
  .slider-image {
    /* height: 500px; */
    object-fit: cover;
    /* width: 98%; */
    border-radius: 24px;

  }
  .slick-slide img{
    height: auto;
    border-radius: 24px;

  }