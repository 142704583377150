.carousel-image-1{
    background: url("../../../public/images/slider/1.jpg");
    background-size: cover;
}
.carousel-image-2{
    background: url("../../../public/images/slider/2.jpg");
    background-size: cover;
}
.carousel-image-3{
    background: url("../../../public/images/slider/3.png");
    background-size: cover;
}