.slider-contain {
  width: 100%;
  /* height: 200px; */
  margin-bottom: 8%;
  position: relative;
  /* overflow: hidden; */
}

.slick-prev:before,
.slick-next:before {
  color: black; /* Arrow color */
}

.slick-prev,
.slick-next {
  width: 30px;
  height: 30px;
  background: white; /* Arrow background */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; /* Ensure arrows are above images */
  position: absolute; /* Ensure arrows are positioned relative to the container */
}

.slick-prev {
  left: -25px; /* Adjust the position if needed */
  top: calc(50% - 15px); /* Center the arrow vertically */
}

.slick-next {
  right: -25px; /* Adjust the position if needed */
  top: calc(50% - 15px); /* Center the arrow vertically */
}

.slider-images {
  /* height: 200px !important; */
  object-fit: cover;
  width: 100%;
  border-radius: 8px; /* Optional: Add some rounding to the images */
}
/* .carousel-image-1{
  background: url('../images/slider/1.jpg');
  background-size: cover;
}
.carousel-image-2{
  background: url('../images/slider/2.jpg');
  background-size: cover;
}
.carousel-image-3{
  background: url('../images/slider/3.png');
  background-size: cover;
} */
 .carousel-image-1{
  background-image: '..images/slider/1.jpg';
 }
@media (min-width: 768px) {

  .slider-images {
    height: 120px !important; /* Increased height for mobile */
  }
}

.slick-track {
  display: flex;
  align-items: center;
}
