.collection-item-wrapper {
    text-align: center;
    max-width: 500px; /* changed max-width 300px to 500px by sohaib*/
    width: 100%;
  }
  
  .sold-out-chip {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
    z-index: 1;
  }
  

  .collection-item {
    border: 1px solid black;
    border-radius: 20px;
    overflow: hidden;
    text-align: center;
    box-sizing: border-box;
    background: #fff;
    position: relative;
    padding: 10px 0;
  }
  
  .image-container {
    position: relative;
    width: 93%;
    height: 0;
    padding-bottom: 115%; /* Adjust to better match the image aspect ratio */
    margin: 10px auto;
  }
  
  .item-image,
  .hover-image {
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  
  .hover-image {
    opacity: 0;
    cursor: pointer;
  }
  
  .collection-item:hover .hover-image {
    opacity: 1;
  }
  
  .collection-item:hover .item-image {
    opacity: 0;
  }
  
  .item-title {
    display: flex;
    font-size: 1rem;
    margin: 10px 0 5px;
  }
  
  .item-title:hover {
    text-decoration: underline;
  }
  
  .item-price {
    display: flex;
    font-size: 1.1rem;
    color: #000000;
    margin-bottom: 10px;
  }
  
  .view-all-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .view-all-link {
    font-size: 1em;
    color: black;
    text-decoration: underline;
    transition: color 0.3s ease;
  }
  
  .view-all-link:hover {
    text-decoration-thickness: 3px;
    color: black;
  }
  