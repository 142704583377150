@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap");

.collection-container {
  padding: 20px;
  font-family: "Lora", serif;
}

.collection-title {
  display: flex;
  text-align: center;
  font-size: 1.57em;
  margin-bottom: 10px;
  letter-spacing: 0.05em;
  padding-left: 6%;
}

.collection-subtitle {
  display: flex;
  text-align: center;
  color: #404040;
  font-size: 1.1em;
  margin-bottom: 30px;
  letter-spacing: 0.01em;
  padding-left: 6%;
}

.collection-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}

.collection-item-wrapper {
  text-align: center;
  max-width: 300px;
  width: 100%;
}

.collection-item {
  border: 1px solid black;
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
  box-sizing: border-box;
  background: #fff;
  position: relative;
  padding: 10px 0;
}

.image-container {
  position: relative;
  width: 93%;
  height: 0;
  padding-bottom: 115%; /* Adjust to better match the image aspect ratio */
  margin: 10px auto;
}

.item-image,
.hover-image {
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.hover-image {
  opacity: 0;
  cursor: pointer;
}

.collection-item:hover .hover-image {
  opacity: 1;
}

.collection-item:hover .item-image {
  opacity: 0;
}

.item-title {
  display: flex;
  font-size: 1.2em;
  margin: 10px 0 5px;
}

.item-title:hover {
  text-decoration: underline;
}

.item-price {
  display: flex;
  font-size: 1em;
  color: #555;
  margin-bottom: 10px;
}

.view-all-container {
  text-align: center;
  margin-top: 20px;
}

.view-all-link {
  font-size: 1em;
  color: #d4a373;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.view-all-link:hover {
  text-decoration-thickness: 3px;
  color: #a3613d;
}


@media (max-width: 768px) {
  .collection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .collection-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    padding-left: 0;
  }

  .collection-subtitle {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}
