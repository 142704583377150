.nav {
    padding: 5px;
  }
  
  .manu {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
    
  }
  
  .menuitem {
    font-size: 16px;
    list-style: none;
    margin: 1px 10px;
  }

  .menuitem:hover {
    border-bottom: #000000 2px solid;
    cursor: pointer;
  }
  